import React, { useState } from "react";
import "./share_blog.css";

const ShareBlog = () => {
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const currentUrl = window.location.href;

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(currentUrl)
      .then(() => {
        setAlertVisible(true);
        // Hide alert after 1.5 seconds and close the popup
        setTimeout(() => {
          setAlertVisible(false);
          setPopupOpen(false);
        }, 1500);
      })
      .catch(err => {
        console.error("Failed to copy link: ", err);
      });
  };

  const togglePopup = () => {
    setPopupOpen(!isPopupOpen);
  };

  return (
    <div className="blog-container">

      {/* Share Button */}
      <div className="share-section">
        <div className="share-content">
          <p>Share your interested blog with your friends!</p>
        </div>
        <button className="share-button" onClick={togglePopup}>
          Share
        </button>
      </div>

      {/* Popup */}
      {isPopupOpen && (
        <div className="popup">
          <div className="popup-content">
            <span className="close-button" onClick={togglePopup}>
              &times;
            </span>
            <h2>Share this blog</h2>
            <p>{currentUrl}</p>
            <button className="copy-button" onClick={copyToClipboard}>
              Copy URL
            </button>
          </div>
        </div>
      )}

      {/* Alert */}
      {alertVisible && <div className="alert">Link copied to clipboard!</div>}
    </div>
  );
};

export default ShareBlog;









// import React, { useEffect, useState } from "react";
// import { FaThumbsUp, FaShareAlt, FaBell } from "react-icons/fa";
// import Modal from "react-modal";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css"; // Importing Toastify CSS

// Modal.setAppElement("#root");

// const ShareBlog = ({ blogId }) => {
//   const [isPopupOpen, setPopupOpen] = useState(false);
//   const [isModalOpen, setModalOpen] = useState(false);
//   const [liked, setLiked] = useState(false);
//   const [subscribed, setSubscribed] = useState(false);
//   const [email, setEmail] = useState("");
//   const [loading, setLoading] = useState(false);
//   const [likes, setLikes] = useState(0);

//   const currentUrl = window.location.href;

//   const notifyCopy = () => toast.success("Link copied to clipboard!");
//   const notifyLike = () => toast.info(liked ? "You already liked this blog!" : "You liked this blog!");
//   const notifySubscribe = () => toast.success("Subscribed successfully!");

//   const copyToClipboard = () => {
//     navigator.clipboard.writeText(currentUrl).then(() => {
//       notifyCopy();
//     });
//   };

//   const togglePopup = () => {
//     setPopupOpen(!isPopupOpen);
//   };

//   const toggleLike = async () => {
//     if (!liked) {
//       setLiked(true);
//       setLikes(likes + 1);
//       notifyLike();
//     } else {
//       notifyLike();
//     }
//   };

//   const fetchLikes = async () => {
//     try {
//       const response = await fetch(
//         `http://localhost:5001/api/blog/${blogId}/like-count`
//       );
//       const data = await response.json();
//       setLikes(data.likes);
//     } catch (error) {
//       console.error("Failed to fetch likes:", error);
//     }
//   };

//   useEffect(() => {
//     fetchLikes();
//   }, [blogId]);

//   const toggleSubscribe = () => {
//     setModalOpen(true);
//   };

//   const subscribeToBlog = async (e) => {
//     e.preventDefault();
//     setLoading(true);

//     try {
//       const response = await fetch(
//         "https://ardra-a-ibackend.vercel.app/api/blog/subscribe",
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify({ email }),
//         }
//       );

//       if (response.ok) {
//         setSubscribed(true);
//         setEmail("");
//         notifySubscribe();
//         setModalOpen(false);
//       } else {
//         toast.error("Error occurred. Try again.");
//       }
//     } catch (error) {
//       console.error("Error:", error);
//       toast.error("Error occurred. Try again.");
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <div className="flex flex-col items-center justify-center my-10">
//       {/* Blog Interaction Card with Modern Look */}
//       <div className="backdrop-blur-md bg-white/80 dark:bg-black/50 border border-gray-200 dark:border-gray-700 rounded-2xl p-8 w-full max-w-xl md:max-w-2xl transition-all duration-300 shadow-lg hover:shadow-2xl">
//         <p className="text-center text-3xl font-bold text-gray-800 dark:text-gray-100 mb-6 drop-shadow-lg">
//           Engage with this Blog!
//         </p>

//         <div className="flex justify-between items-center space-x-6">
//           {/* Share Button */}
//           <button
//             onClick={togglePopup}
//             className="bg-blue-600 text-white rounded-full p-4 shadow-lg hover:shadow-2xl transition-transform transform hover:scale-110"
//             title="Share this blog"
//           >
//             <FaShareAlt className="text-3xl" />
//           </button>

//           {/* Like Button */}
//           <button
//             onClick={toggleLike}
//             className={`relative rounded-full p-4 shadow-lg transition-transform transform hover:scale-110 ${
//               liked ? "bg-red-600 text-white" : "bg-gray-300 hover:bg-red-500"
//             }`}
//             title={liked ? "Liked" : "Like this blog"}
//           >
//             <FaThumbsUp className="text-3xl" />
//             <span className="absolute -top-2 -right-2 bg-red-600 text-white rounded-full px-2 py-1 text-xs">
//               {likes}
//             </span>
//           </button>

//           {/* Subscribe Button */}
//           <button
//             onClick={toggleSubscribe}
//             className={`rounded-full p-4 shadow-lg transition-transform transform hover:scale-110 ${
//               subscribed
//                 ? "bg-green-600 text-white"
//                 : "bg-gray-300 hover:bg-green-500"
//             }`}
//             title={subscribed ? "Subscribed" : "Subscribe"}
//           >
//             <FaBell className="text-3xl" />
//           </button>
//         </div>

//         {/* Feedback Section */}
//         <div className="mt-6 text-center">
//           <p className="text-md text-gray-700 dark:text-gray-300">
//             {liked
//               ? "Thanks for liking!"
//               : "Show your appreciation by liking this blog."}
//           </p>
//           <p className="text-md mt-1 text-gray-700 dark:text-gray-300">
//             {subscribed
//               ? "You're subscribed for updates!"
//               : "Subscribe to receive updates directly in your inbox."}
//           </p>
//         </div>
//       </div>

//       {/* Alerts for actions using Toastify */}
//       <ToastContainer position="bottom-center" autoClose={2000} />

//       {/* Popup for sharing */}
//       {isPopupOpen && (
//         <div className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-50">
//           <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full relative">
//             <button
//               className="absolute top-2 right-2 text-gray-400 hover:text-gray-600 text-xl"
//               onClick={togglePopup}
//             >
//               &times;
//             </button>
//             <h2 className="text-lg font-semibold mb-4">Share this Blog</h2>
//             <p className="text-sm break-all mb-4">{currentUrl}</p>
//             <button
//               className="bg-blue-500 text-white py-2 px-4 rounded-lg w-full hover:bg-blue-600 transition"
//               onClick={copyToClipboard}
//             >
//               Copy Link
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Modal for email subscription */}
//       <Modal
//         isOpen={isModalOpen}
//         onRequestClose={() => setModalOpen(false)}
//         className="bg-white rounded-lg shadow-lg p-6 max-w-lg w-full mx-auto"
//         overlayClassName="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center"
//       >
//         <button
//           className="absolute top-4 right-4 text-gray-400 hover:text-gray-600 text-xl"
//           onClick={() => setModalOpen(false)}
//         >
//           &times;
//         </button>
//         <h2 className="text-2xl font-bold mb-4">Subscribe to Updates</h2>
//         <form onSubmit={subscribeToBlog} className="space-y-4">
//           <div>
//             <label htmlFor="email" className="block text-gray-600">
//               Enter your email:
//             </label>
//             <input
//               type="email"
//               id="email"
//               className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
//               value={email}
//               onChange={(e) => setEmail(e.target.value)}
//               required
//             />
//           </div>
//           <button
//             type="submit"
//             className="w-full py-2 px-4 bg-green-500 text-white rounded-lg hover:bg-green-600 transition"
//             disabled={loading}
//           >
//             {loading ? "Subscribing..." : "Subscribe"}
//           </button>
//         </form>
//       </Modal>
//     </div>
//   );
// };

// export default ShareBlog;