// BlogPage.js
import React, { useState } from "react";
import { Link } from "react-router-dom";
import BlogData from "./BlogData";
import "./BlogPage.css";
import Pagination from "./Pagination/Pagination";
import SubscribeForm from "./SubscribeForm/SubscribeForm";
import { Helmet } from "react-helmet";

const BlogPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPageFirstPage = 6;
  const itemsPerPageOtherPages = 9;

  // Calculate the total number of pages
  const totalPages = Math.ceil(
    (BlogData.length + 1 - itemsPerPageFirstPage) / itemsPerPageOtherPages + 1
  );

  // Determine the starting and ending indices for the current page
  const startIdx =
    currentPage === 1
      ? 0
      : itemsPerPageFirstPage + (currentPage - 2) * itemsPerPageOtherPages;
  const endIdx =
    currentPage === 1
      ? itemsPerPageFirstPage
      : startIdx + itemsPerPageOtherPages;

  const handlePageChange = page => {
    setCurrentPage(page);
    window.scrollTo(0, 0); // Scroll to top when changing pages
  };

  return (
    <div className="blog-page">
      {/* seo on main blog page */}
      <Helmet>
        <title>Ardra AI Blog - Explore Articles, Case Studies & More</title>

        <meta
          name="description"
          content="Read the latest articles, case studies, and product spotlights from Ardra AI. Stay informed about industry trends, AI innovations, and energy management insights."
        />
        <meta
          name="keywords"
          content=" Ardra AI Blog, AI in Energy, Artificial Intelligence, Energy Management Solutions, AI Case Studies, AI in Industry, AI Innovations, Energy Efficiency, Sustainable Energy, AI for Energy Optimization, AI Articles and Insights, Smart Energy Solutions, AI Technology in Energy, Machine Learning in Energy Management, Energy Analytics, AI-driven Solutions."
        />
        <meta name="author" content="Ardra AI" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Ardra AI Blog" />
        <meta
          property="og:description"
          content="Explore AI insights, case studies, and product highlights in the energy sector from Ardra AI."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.ardra.ai/blog" />
        <meta property="og:image" content="link_to_a_featured_image" />
        <meta property="og:site_name" content="Ardra AI Blog" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Ardra AI Blog" />
        <meta
          name="twitter:description"
          content="Discover the latest blog articles, case studies, and AI solutions for energy management."
        />
        <meta name="twitter:image" content="link_to_a_featured_image" />
      </Helmet>

      <div className="all-blogs">
        <h1 className="blog-heading">The Ardra AI blog</h1>

        {/* Navigation Bar */}
        <nav className="blog-nav">
          <ul className="blog-nav-list">
            <li className="blog-nav-item">
              <Link to="/blog/articles" className="blog-nav-link">
                Articles
              </Link>
            </li>
            <li className="blog-nav-item">
              <Link to="/blog/case-studies" className="blog-nav-link">
                Case Studies
              </Link>
            </li>
            <li className="blog-nav-item">
              <Link to="/blog/product-spotlight" className="blog-nav-link">
                Product Spotlight
              </Link>
            </li>
          </ul>
        </nav>

        {/* Main Blog Item - Show only on the first page */}
        {currentPage === 1 && BlogData.find(item => item.id === 1) && (
          <div className="blog-main-item">
            <img
              src={BlogData.find(item => item.id === 1).image}
              alt="Main Blog"
              className="blog-main-item-image"
            />
            <div className="blog-main-item-content">
              <p className="blog-main-item-category">
                {BlogData.find(item => item.id === 1).type}
              </p>
              <Link
                to={BlogData.find(item => item.id === 1).link}
                className="blog-main-item-link"
              >
                <h3 className="blog-main-item-title">
                  {BlogData.find(item => item.id === 1).title}
                </h3>
              </Link>
              <p className="blog-main-item-summary">
                {BlogData.find(item => item.id === 1).summary}
              </p>
            </div>
          </div>
        )}

        {/* Other Blogs Section - Show "Most Recent" heading only on the first page */}
        {currentPage === 1 && (
          <div className="other-blog">
            <h2>Most Recent</h2>
          </div>
        )}

        {/* Blog Items */}
        <div className="blog-items">
          {BlogData.filter(item => item.id !== 1)
            .slice(startIdx, endIdx)
            .map(item => (
              <div key={item.id} className="blog-item">
                {item.image && (
                  <img
                    src={item.image}
                    alt={item.title}
                    className="blog-item-image"
                  />
                )}
                <p className="blog-item-category">
                  {item.type ? item.type : item.category}
                </p>
                <a href={item.link} className="blog-item-link">
                  <h3 className="blog-item-title">{item.title}</h3>
                </a>
                <p className="blog-item-summary">{item.summary}</p>
              </div>
            ))}
        </div>

        {/* <SubscribeForm /> */}

        {/* Pagination Component */}
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default BlogPage;
