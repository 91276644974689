import React, { useState } from "react";
import { Link } from "react-router-dom";
import BlogData from "./BlogData";
import "./BlogPage.css";
import Pagination from "./Pagination/Pagination";
import TheFutureOfEnergyManagementmain from "../../Assist/Blogs/The-Future-Of-Energy-Management-main.jpg";
import { Helmet } from "react-helmet";

const BlogArticles = () => {
  const articles = BlogData.filter(item => item.category === "Article");

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPageFirstPage = 7;
  const itemsPerPageOtherPages = 9;

  const totalPages = Math.ceil(
    (articles.length + 1 - itemsPerPageFirstPage) / itemsPerPageOtherPages + 1
  );

  const startIdx =
    currentPage === 1
      ? 1 // Start from the second article on the first page
      : itemsPerPageFirstPage + (currentPage - 2) * itemsPerPageOtherPages;
  const endIdx =
    currentPage === 1
      ? itemsPerPageFirstPage
      : startIdx + itemsPerPageOtherPages;

  const handlePageChange = page => {
    setCurrentPage(page);
    window.scrollTo(0, 0);
  };

  return (
    <div className="blog-page">
      {/* seo on main article page*/}
      <Helmet>
        <title>Explore Latest AI Articles - Ardra AI Blog</title>
        <meta
          name="description"
          content="Read insightful articles about AI, machine learning, and energy management on Ardra AI Blog. Stay updated with the latest trends in AI technology."
        />
        <meta
          name="keywords"
          content=" AI articles, machine learning, energy management, Ardra AI, AI trends, artificial intelligence, energy efficiency, sustainable energy, AI innovations, smart energy solutions, AI technology, data analytics in energy, AI-driven insights, AI in energy management, AI solutions, renewable energy technology."
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Ardra AI" />
        <meta
          property="og:title"
          content="Explore Latest AI Articles - Ardra AI Blog"
        />
        <meta
          property="og:description"
          content="Read insightful articles about AI, machine learning, and energy management on Ardra AI Blog."
        />
        <meta property="og:image" content={TheFutureOfEnergyManagementmain} />
        <meta property="og:url" content="https://www.ardra.ai/blog/articles" />
        <meta property="og:type" content="website" />
      </Helmet>

      <div className="all-blogs">
        <h1 className="blog-heading">Articles</h1>

        {/* Navigation Bar */}
        <nav className="blog-nav">
          <ul className="blog-nav-list">
            <li className="blog-nav-item">
              <Link to="/blog/articles" className="blog-nav-link active">
                Articles
              </Link>
            </li>
            <li className="blog-nav-item">
              <Link to="/blog/case-studies" className="blog-nav-link">
                Case Studies
              </Link>
            </li>
            <li className="blog-nav-item">
              <Link to="/blog/product-spotlight" className="blog-nav-link">
                Product Spotlight
              </Link>
            </li>
          </ul>
        </nav>

        {/* Main Blog Item - Show only on the first page */}
        {currentPage === 1 && articles.length > 0 && (
          <div className="blog-main-item">
            <img
              src={TheFutureOfEnergyManagementmain}
              alt="Main Blog"
              className="blog-main-item-image"
            />
            <div className="blog-main-item-content">
              <p className="blog-main-item-category">{articles[0].type}</p>
              <a href={articles[0].link} className="blog-main-item-link">
                <h3 className="blog-main-item-title">{articles[0].title}</h3>
              </a>
              <p className="blog-main-item-summary">{articles[0].summary}</p>
            </div>
          </div>
        )}
        {currentPage === 1 && <hr className="blog-divider" />}

        {/* Blog Items */}
        <div className="blog-items">
          {articles.slice(startIdx, endIdx).map(item => (
            <div key={item.id} className="blog-item">
              {item.image && (
                <img
                  src={item.image}
                  alt={item.title}
                  className="blog-item-image"
                />
              )}
              <p className="blog-item-category">{item.type}</p>
              <a href={item.link} className="blog-item-link">
                <h3 className="blog-item-title">{item.title}</h3>
              </a>
              <p className="blog-item-summary">{item.summary}</p>
            </div>
          ))}
        </div>

        {/* Pagination Component */}
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default BlogArticles;
