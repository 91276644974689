import React, { useState } from "react";
import { Link } from "react-router-dom";
import BlogData from "./BlogData";
import "./BlogPage.css";
import Pagination from "./Pagination/Pagination";
import WaysArdraAICanHelpReduceEnergyCostsmain from "../../Assist/Blogs/Ways-Ardra-AI-Can-Help-Reduce-Energy-Costs-main.jpg";
import { Helmet } from "react-helmet";

const BlogProductSpotlight = () => {
  const productSpotlights = BlogData.filter(
    item => item.category === "Product-spotlight"
  );

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPageFirstPage = 7;
  const itemsPerPageOtherPages = 9;

  const totalPages = Math.ceil(
    (productSpotlights.length + 1 - itemsPerPageFirstPage) /
      itemsPerPageOtherPages +
      1
  );

  // Determine the starting and ending indices for the current page
  const startIdx =
    currentPage === 1
      ? 1
      : itemsPerPageFirstPage + (currentPage - 2) * itemsPerPageOtherPages;
  const endIdx =
    currentPage === 1
      ? itemsPerPageFirstPage
      : startIdx + itemsPerPageOtherPages;

  const handlePageChange = page => {
    setCurrentPage(page);
    window.scrollTo(0, 0);
  };

  return (
    <div className="blog-page">
      <Helmet>
        <title>Ardra AI Blogs | Product Spotlight</title>
        <meta
          name="description"
          content="Discover the latest insights and product spotlights from Ardra AI, including AI-driven energy management solutions and innovative energy optimization strategies."
        />
        <meta
          name="keywords"
          content="Product Spotlight, Blog Product Spotlight, Energy Costs, Ways to Reduce Energy Costs, Ardra AI, Energy Management, AI in Energy Management, Blog Articles, Case Studies, Reducing Energy Costs, Blog Navigation, Featured Products, Energy Solutions, Pagination Component, AI Solutions for Energy, Blog Main Item, Product Highlights, Ardra AI Blog"
        />
        <meta name="author" content="Ardra AI" />
        <meta
          property="og:title"
          content="Product Spotlight | Ardra AI Blogs"
        />
        <meta
          property="og:description"
          content="Explore Ardra AI's top products in energy management and learn how AI can help reduce energy costs."
        />
        <meta
          property="og:image"
          content={WaysArdraAICanHelpReduceEnergyCostsmain}
        />
        <meta
          property="og:url"
          content="https://ardra.ai/blog/product-spotlight"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Product Spotlight | Ardra AI Blogs"
        />
        <meta
          name="twitter:description"
          content="Discover Ardra AI's product spotlights on energy management."
        />
        <meta
          name="twitter:image"
          content={WaysArdraAICanHelpReduceEnergyCostsmain}
        />
      </Helmet>

      <div className="all-blogs">
        <h1 className="blog-heading">Product Spotlight</h1>
        {/* Navigation Bar */}
        <nav className="blog-nav">
          <ul className="blog-nav-list">
            <li className="blog-nav-item">
              <Link to="/blog/articles" className="blog-nav-link">
                Articles
              </Link>
            </li>
            <li className="blog-nav-item">
              <Link to="/blog/case-studies" className="blog-nav-link">
                Case Studies
              </Link>
            </li>
            <li className="blog-nav-item">
              <Link
                to="/blog/product-spotlight"
                className="blog-nav-link active"
              >
                Product Spotlight
              </Link>
            </li>
          </ul>
        </nav>

        {/* Main Blog Item - Show only on the first page */}
        {currentPage === 1 && productSpotlights.length > 0 && (
          <div className="blog-main-item">
            <img
              src={WaysArdraAICanHelpReduceEnergyCostsmain}
              alt="Main Blog"
              className="blog-main-item-image"
            />
            <div className="blog-main-item-content">
              <p className="blog-main-item-category">Product Spotlight</p>
              <a
                href={productSpotlights[0].link}
                className="blog-main-item-link"
              >
                <h3 className="blog-main-item-title">
                  {productSpotlights[0].title}
                </h3>
              </a>
              <p className="blog-main-item-summary">
                {productSpotlights[0].summary}
              </p>
            </div>
          </div>
        )}

        {currentPage === 1 && <hr className="blog-divider" />}
        {/* Blog Items */}
        <div className="blog-items">
          {productSpotlights.slice(startIdx, endIdx).map(item => (
            <div key={item.id} className="blog-item">
              {item.image && (
                <img
                  src={item.image}
                  alt={item.title}
                  className="blog-item-image"
                />
              )}
              <p className="blog-item-category">{item.category}</p>
              <a href={item.link} className="blog-item-link">
                <h3 className="blog-item-title">{item.title}</h3>
              </a>
              <p className="blog-item-summary">{item.summary}</p>
            </div>
          ))}
        </div>

        {/* Pagination Component */}
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default BlogProductSpotlight;
