import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import "../Detail_blog.css";
import BlogData from "../BlogData";
import "../BlogPage.css";
import StreamliningEnergyManagementforaUniversityinner from "../../../Assist/Blogs/Streamlining-Energy-Management-for-a-University-inner.jpg";
import StreamliningEnergyManagementforaUniversitymain from "../../../Assist/Blogs/Streamlining-Energy-Management-for-a-University-main.jpg";
import ShareBlog from "../share_blog/share_blog";
import { Helmet } from "react-helmet";
// import { BlogIDs } from "../BlogData";

const StreamliningEnergyManagementforaUniversity = () => {
  const id16 = 16;
  const { title } = useParams();
  const category = "Case-study";
  const readTime = "5 min read";
  const [activeSection, setActiveSection] = useState("");
  const [isTableOfContentsExpanded, setTableOfContentsExpanded] =
    useState(false);
  const sections = useRef([]);

  const tocSections = [
    { id: "section1", title: "Client Profile" },
    { id: "section2", title: "Problem Overview" },
    {
      id: "section3",
      title:
        "Solution: Implementing ArdraGPT for Automated Energy Reporting and Optimization",
    },
    { id: "section4", title: "Key Actions Taken" },
    { id: "section5", title: "Results" },
    { id: "section6", title: "Client Testimonial" },
    { id: "section7", title: "Conclusion" },
    {
      id: "section8",
      title: "Interested in optimizing your institution’s energy use?",
    },
  ];

  // Filter for only 3 items by ID
  const selecteditems = BlogData.filter(item => [6, 7, 5].includes(item.id));

  useEffect(() => {
    const handleScroll = () => {
      const sectionOffsets = tocSections.map(section => {
        const el = sections.current.find(ref => ref?.id === section.id);
        return {
          id: section.id,
          offsetTop: el ? el.offsetTop : 0,
          offsetHeight: el ? el.offsetHeight : 0,
        };
      });

      const currentPosition = window.scrollY + 150; // Adjust for sticky navbar height

      for (const section of sectionOffsets) {
        if (
          currentPosition >= section.offsetTop &&
          currentPosition < section.offsetTop + section.offsetHeight
        ) {
          setActiveSection(section.id);
          break;
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [tocSections]);

  const handleClick = id => {
    setActiveSection(id); // Change the active section on click
    const sectionElement = sections.current.find(ref => ref?.id === id);
    if (sectionElement) {
      window.scrollTo({
        top: sectionElement.offsetTop - 130, // Adjust for sticky navbar height
        behavior: "smooth",
      });
    }
  };

  const toggleTableOfContents = () => {
    setTableOfContentsExpanded(!isTableOfContentsExpanded);
  };

  return (
    <div className="future-energy-management">
      <Helmet>
        <title>
          Case Study - Streamlining Energy Management for a University with
          ArdraGPT
        </title>
        <meta
          name="description"
          content="This case study highlights how Ardra AI's solutions were implemented in a modern smart building complex to achieve optimal energy efficiency and reduce overall energy costs."
        />
        <meta
          name="keywords"
          content="Energy Management Solutions, Automated Energy Reporting, University Energy Efficiency, ArdraGPT, AI Energy Management, Energy Optimization Strategies, Smart Building Management, Energy Consumption Analytics, Sustainable Energy Practices, Predictive Energy Management, Energy Management Software, Automation in Energy Reporting, University Case Study, Reducing Energy Costs, Efficiency in Energy Use, Energy Management Challenges, Data-Driven Energy Solutions, Energy Efficiency Innovations, Ardra AI Solutions, Real-time Energy Monitoring."
        />
        <meta
          property="og:title"
          content="Case Study: Streamlining Energy Management for a University"
        />
        <meta
          property="og:description"
          content="This case study highlights how Ardra AI's solutions were implemented in a modern smart building complex to achieve optimal energy efficiency and reduce overall energy costs."
        />
        <meta
          property="og:image"
          content={StreamliningEnergyManagementforaUniversitymain}
        />
        <meta
          property="og:url"
          content="https://ardra.ai/blog/Streamlining-Energy-Management-for-a-University"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Case Study: Streamlining Energy Management for a University"
        />
        <meta
          name="twitter:description"
          content="This case study highlights how Ardra AI's solutions were implemented in a modern smart building complex to achieve optimal energy efficiency and reduce overall energy costs."
        />
        <meta
          name="twitter:image"
          content={StreamliningEnergyManagementforaUniversitymain}
        />
      </Helmet>

      <div className="blog-intro-section">
        <div className="blog-intro-image">
          <img
            src={StreamliningEnergyManagementforaUniversitymain}
            alt="Streamlining Energy Management for a University"
          />
        </div>
        <div className="blog-intro-text">
          <p className="blog-item-category">{category}</p>
          <h2>
            Case Study: Streamlining Energy Management for a University with
            ArdraGPT
          </h2>
          <p className="main-p">
            This case study highlights how Ardra AI’s solutions were implemented
            in a modern smart building complex to achieve optimal energy
            efficiency and reduce overall energy costs.
          </p>
          <p className="blog-author">
            By the team at Ardra AI<p className="blog-date"> 26th sep 2024</p>
          </p>
        </div>
      </div>

      <div className="content-container">
        {/* Table of Contents */}
        <aside
          className={`table-of-contents ${
            isTableOfContentsExpanded ? "expanded" : "collapsed"
          }`}
        >
          <div className="toc-toggle" onClick={toggleTableOfContents}>
            <span>TABLE OF CONTENTS</span>
            <span
              className={`chevron ${
                isTableOfContentsExpanded ? "expanded" : ""
              }`}
            >
              <i
                className={`fa-solid ${
                  isTableOfContentsExpanded
                    ? "fa-chevron-down"
                    : "fa-chevron-right"
                }`}
              ></i>
            </span>
          </div>
          <ul>
            {tocSections.map(section => (
              <li
                key={section.id}
                className={activeSection === section.id ? "active" : ""}
              >
                <a
                  href={`#${section.id}`}
                  onClick={e => {
                    e.preventDefault(); // Prevent default anchor behavior
                    handleClick(section.id);
                  }}
                >
                  {section.title}
                </a>
              </li>
            ))}
          </ul>
        </aside>

        {/* Main Content */}
        <div className="main-content">
          <div className="content-header">
            <div className="read-time">
              <i className="fa-regular fa-clock"></i> {readTime}
            </div>
            <div className="social-icons">
              <a
                href="https://twitter.com/ArdraAi"
                aria-label="Twitter"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-brands fa-x-twitter fa-lg fa-fade"></i>
              </a>
              <a
                href="https://www.linkedin.com/company/88407851/admin/feed/posts/"
                aria-label="LinkedIn"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-brands fa-linkedin-in fa-xl fa-fade"></i>
              </a>
              <a
                href="https://www.instagram.com/ardra.ai.india/"
                aria-label="Instagram"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-brands fa-instagram fa-xl fa-fade"></i>
              </a>
            </div>
          </div>

          {/* Content Sections */}
          <div className="content-sections">
            <div
              id="section1"
              ref={el => (sections.current[0] = el)}
              className="content-section"
            >
              <h3>Client Profile:</h3>
              <br />
              <p>
                <b>● Name: </b> Dr. Suresh Iyer
              </p>
              <p>
                <b>● Age: </b>48
              </p>
              <p>
                <b>● Role: </b>Energy Manager
              </p>

              <p>
                <b>● Location: </b>Puttaparthi, Andhra Pradesh, India
              </p>
              <p>
                <b>● Institution: </b> South Indian University with multiple
                buildings and campuses
              </p>
              <p>
                <b>● Challenges: </b>High workload, manual data reporting, low
                productivity, and time wastage
              </p>
              <p>
                <b>● Goal: </b> Automate reporting, optimize energy management,
                and increase overall productivity
              </p>
            </div>

            <div
              id="section2"
              ref={el => (sections.current[1] = el)}
              className="content-section"
            >
              <h3>Problem Overview</h3>

              <p>
                Suresh Iyer, a seasoned energy manager at a major South Indian
                university, was overwhelmed by the volume of manual work
                required to manage energy consumption across multiple campuses.
                His team was constantly burdened with collecting data, compiling
                energy reports, and manually tracking energy usage. This
                time-consuming process led to inefficiencies, errors in
                reporting, and missed opportunities for improving energy
                efficiency.
              </p>
              <p>
                Suresh's primary goal was to <b>automate reporting</b>, reduce
                the manual workload, and make data-driven decisions to optimize
                energy consumption across the university’s buildings.
              </p>
            </div>

            <div
              id="section3"
              ref={el => (sections.current[2] = el)}
              className="content-section"
            >
              <img
                src={StreamliningEnergyManagementforaUniversityinner}
                alt="Streamlining Energy Management for a University"
                className="innerimage"
                style={{ width: "100%", height: "auto" }}
              />
              <br />
              <br />
              <br />
              <h3>
                Solution: Implementing ArdraGPT for Automated Energy Reporting
                and Optimization
              </h3>
              <br />

              <p>
                After exploring various options, Suresh chose to implement{" "}
                <b>ArdraGPT</b>, a conversational AI solution designed to
                automate energy management processes. ArdraGPT was deployed to:
              </p>
              <p>
                <b>1. Automate Energy Data Collection: </b>
                ArdraGPT integrated with the university’s existing systems,
                allowing for real-time data collection from various buildings,
                including student hostels, administrative blocks, and academic
                buildings.
              </p>
              <p>
                <b>2. Streamline Reporting: </b>
                The AI agent generated automated reports, reducing the manual
                time Suresh’s team spent compiling data and generating insights.
              </p>
              <p>
                <b>3. Predictive Analytics: </b>
                ArdraGPT analyzed historical energy consumption patterns,
                providing predictive insights to prevent energy wastage and
                optimize energy usage.
              </p>
              <p>
                <b>4. Centralized Monitoring: </b>
                With ArdraGPT’s centralized dashboard, Suresh could monitor
                energy consumption across multiple campuses from one location.
              </p>
              <p>
                <b>5. Smart Recommendations: </b>
                The AI tool provided smart recommendations for energy savings,
                such as adjusting HVAC systems, optimizing lighting schedules,
                and automating equipment shutdowns during off-hours.
              </p>
            </div>

            <div
              id="section4"
              ref={el => (sections.current[3] = el)}
              className="content-section"
            >
              <h3>Key Actions Taken</h3>
              <br />
              <p>
                <b>1. Automated Reporting and Insights: </b>
                ArdraGPT automatically generated energy reports, eliminating the
                need for manual data entry. The AI analyzed energy usage in
                real-time and provided detailed, actionable insights without
                Suresh having to spend hours compiling data.
              </p>
              <p>
                <b>2. Energy Optimization and Alerts: </b>
                The system sent automated alerts about energy inefficiencies,
                such as unusual spikes in energy usage. Suresh was able to act
                quickly, reducing energy waste.
              </p>
              <p>
                <b>3. Productivity Boost: </b>
                By automating the reporting and monitoring processes, ArdraGPT
                significantly reduced the workload for Suresh and his team,
                allowing them to focus on higher-level strategic tasks instead
                of daily, repetitive work.
              </p>
              <p>
                <b>4. Long-Term Energy Planning: </b>
                ArdraGPT provided detailed forecasts and trends in energy usage,
                helping Suresh plan for future energy needs and make long-term
                investments in energy efficiency upgrades, such as solar panels
                and energy-efficient HVAC systems.
              </p>
            </div>
            <div
              id="section5"
              ref={el => (sections.current[4] = el)}
              className="content-section"
            >
              <h3>The Results</h3>
              <p>
                <b>● 50% Reduction in Reporting Time: </b>
                By automating data collection and reporting, Suresh’s team saved{" "}
                <b>over 50% of their time</b> on reporting tasks.
              </p>
              <p>
                <b>● 20% Improvement in Energy Efficiency: </b>
                The university saw a <b>20% improvement</b> in energy efficiency
                across its campuses, thanks to the real-time insights and smart
                recommendations provided by ArdraGPT.
              </p>
              <p>
                <b>● Reduction in Manual Workload: </b>
                With automated reports, Suresh’s team could redirect their
                efforts to strategic energy management initiatives, improving
                overall productivity by 30%.
              </p>
              <p>
                <b>● Proactive Energy Management: </b>
                The university avoided costly energy spikes and reduced energy
                wastage by acting on ArdraGPT’s automated alerts and
                recommendations.
              </p>
            </div>
            <div
              id="section6"
              ref={el => (sections.current[5] = el)}
              className="content-section"
            >
              <h3>Client Testimonial</h3>
              <p style={{ fontStyle: "italic" }}>
                "ArdraGPT has completely changed the way we manage energy at the
                university. Previously, we spent hours on manual data collection
                and reporting, which was error-prone and inefficient. Now, with
                everything automated, we’re able to focus on what really matters
                — improving energy efficiency and optimizing our resources. It’s
                been a game-changer for our team!" <br />— <b>Suresh Iyer</b>,
                Energy Manager, South Indian University
              </p>
            </div>
            <div
              id="section7"
              ref={el => (sections.current[6] = el)}
              className="content-section"
            >
              <h3>Conclusion</h3>
              <p>
                With ArdraGPT, Suresh Iyer was able to transform energy
                management at his university, reducing the manual workload,
                improving energy efficiency, and boosting overall productivity.
                ArdraGPT provided real-time insights, predictive analytics, and
                smart recommendations that allowed Suresh to take control of the
                university’s energy consumption, resulting in significant cost
                savings and operational improvements.
              </p>
              <p>
                If you are an energy manager facing similar challenges,{" "}
                <b>ArdraGPT</b> can help you automate your processes and achieve
                better energy management outcomes.
              </p>
            </div>
            <div
              id="section8"
              ref={el => (sections.current[7] = el)}
              className="content-section"
            >
              <h3>Interested in optimizing your institution’s energy use?</h3>
              <p>
                Contact Ardra AI today for a free demo and see how ArdraGPT can
                streamline your energy management efforts.
              </p>
              <br />
              <p>
                [For data privacy reasons name of the owner is kept
                hidden/changed]
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* {BlogIDs.map(blogId => (  */}
      {/* <ShareBlog  
           key={id16}
            blogId={id16}  
               /> */}
      {/* ))} */}
      <ShareBlog/>
      <div className="all-blogs">
        <h3 className="blog-detail-heading">Keep reading</h3>
        <div className="blog-items">
          {selecteditems.map(item => (
            <div key={item.id} className="blog-item">
              {item.image && (
                <img
                  src={item.image}
                  alt={item.title}
                  className="blog-item-image"
                />
              )}
              <p className="blog-item-category">
                {item.type ? item.type : item.category}
              </p>
              <a href={item.link} className="blog-item-link">
                <h3 className="blog-item-title">{item.title}</h3>
              </a>
              <p className="blog-item-summary">{item.summary}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default StreamliningEnergyManagementforaUniversity;
