import React, { useState } from "react";
import axios from "axios";
import "./Model.css";


const ModalForm = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    jobTitle: "",
    workEmail: "",
    projectBudget: "",
    projectDetails: "",
    consent: false,
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  if (!isOpen) return null;

  const handleChange = event => {
    const { name, value, type, checked } = event.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async event => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(
        "https://ardra-a-ibackend.vercel.app/submit-form",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Form submitted successfully:", response.data);
      window.alert("Form submitted successfully!");
      onClose(); // Close the modal on success
    } catch (err) {
      console.error("Error submitting the form:", err);
      setError("Failed to submit the form. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={onClose} style={{color:'white'}}>
        <i class="fa-solid fa-xmark"></i>
        </button>
        <h2>Talk to a Generative AI Expert</h2>
        {error && <p className="error-message">{error}</p>}
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <input
              type="text"
              name="firstName"
              placeholder="First name*"
              value={formData.firstName}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="lastName"
              placeholder="Last name*"
              value={formData.lastName}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-row">
            <input
              type="text"
              name="companyName"
              placeholder="Company name*"
              value={formData.companyName}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="jobTitle"
              placeholder="Job title*"
              value={formData.jobTitle}
              onChange={handleChange}
              required
            />
          </div>
          <input
            type="email"
            name="workEmail"
            className="modelEmail"
            placeholder="Work email*"
            value={formData.workEmail}
            onChange={handleChange}
            required
          />
          <div className="form-group">
            <p>Project Budget*</p>
            <label>
              <input
                type="radio"
                name="projectBudget"
                value="<50k"
                onChange={handleChange}
                required
              />
              $2k-$5k
            </label>
            <label>
              <input
                type="radio"
                name="projectBudget"
                value="50-250k"
                onChange={handleChange}
                required
              />
              $5k-$10k
            </label>
            <label>
              <input
                type="radio"
                name="projectBudget"
                value="250k-1m"
                onChange={handleChange}
                required
              />
              $10k-$15k
            </label>
            <label>
              <input
                type="radio"
                name="projectBudget"
                value="1m+"
                onChange={handleChange}
                required
              />
              $15k+
            </label>
          </div>
          <textarea
            name="projectDetails"
            placeholder="Please provide details on your project.*"
            value={formData.projectDetails}
            onChange={handleChange}
            required
          ></textarea>
          <div className="form-group">
            <label>
              <input
                type="checkbox"
                name="consent"
                checked={formData.consent}
                onChange={handleChange}
                required
              />{" "}
              I agree to receive communications from Ardra AI
            </label>
          </div>
          <button type="submit" disabled={loading}>
            {loading ? "Submitting..." : "Submit"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ModalForm;
