import React from "react";
import "./Team.css";

import NishchalSingh from "../Assist/team/Nishchal.png";
import PranjalYadav from "../Assist/team/Pranjal.jpeg";
import SmritiYadav from "../Assist/team/Smriti.jpeg";
import Sunishka from "../Assist/team/Sunishka.jpeg";
import Arti from "../Assist/team/Arti.jpeg";
import Rensi from "../Assist/team/Rensi.jpeg";
import ManojJain from "../Assist/team/manoj.png";
import AnjaliJain from "../Assist/team/Anjali.png";
import Diwakar from "../Assist/team/Diwakar.png";
import Neha from "../Assist/team/Neha.jpeg";
import Pranay from "../Assist/team/Pranay.png";
import Saurabh from "../Assist/team/Saurabh.jpeg";
// import Arjun from "../Assist/team/Arjun.jpeg";
// import Abhimanyu from "../Assist/team/Abhimanyu.jpeg";
// import Keshav from "../Assist/team/Keshav.png";
// import SajalJain from "../Assist/team/Sajal.png";
// import SanskarChauhan from "../Assist/team/Sanskar.png";

const teamMembers = [
  {
    name: "Smriti Yadav",
    role: "Co-Founder and Chief Design Officer",
    img: SmritiYadav,
    description:
      "Designing the future of energy solutions with creativity and precision.",
    linkedin: "https://www.linkedin.com/in/smriti-yadav-433822190",
  },
  {
    name: "Sunishka Jain",
    role: "Head of AI & Product, MS USA",
    img: Sunishka,
    description: "Guiding AI and product innovation with visionary expertise.",
    linkedin:
      "https://www.linkedin.com/in/sunishka-jain?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
  },
  {
    name: "Pranay Dhoot",
    role: "Advisor Data Science, MS Germany",
    img: Pranay,
    description:
      "Transforming data into actionable insights to drive our mission.",
    linkedin:
      "https://www.linkedin.com/in/pranay-dhoot?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base%3BunZ3xJgnTlS95FaVlejTjw%3D%3D",
  },

  {
    name: "Arti Soni",
    role: "Full Stack Web Developer",
    img: Arti,
    description:
      "Passionate Full Stack Web Developer crafting elegant, innovative, and user-centric web applications.",
    linkedin: "https://www.linkedin.com/in/arti-soni/",
  },
  {
    name: "Rensi Dudhat",
    role: "Full Stack Web Developer",
    img: Rensi,
    description:
      "Crafting innovative software solutions with a user-centric approach.",
    linkedin: "https://www.linkedin.com/in/rensi-dudhat-95752125b/",
  },
  // {
  //   name: "Abhimanyu Patwal",
  //   role: "Business Analyst",
  //   img: Abhimanyu,
  //   description:
  //     "Building bridges between startups and market leaders to foster innovation and growth.",
  //   linkedin:
  //     "https://www.linkedin.com/in/abhimanyu-veer-singh-patwal-332164206/",
  // },
  // {
  //   name: "Anjali Jain",
  //   role: "Head of Energy Audits",
  //   img: AnjaliJain,
  //   description:
  //     "Ensuring our energy audits are thorough, accurate, and impactful.",
  //   linkedin: "",
  // },
  {
    name: "Dr Saurabh Sharma",
    role: "NIT Hamirpur, Power Electronics",
    img: Saurabh,
    description:
      "Ensuring our energy audits are thorough, accurate, and impactful.",
    linkedin: "",
  },
  {
    name: "Manoj Jain",
    role: "Head of Finance",
    img: ManojJain,
    description: "Managing our financial health with strategic foresight.",
    linkedin: "",
  },
];

function Team() {
  return (
    <div className="team-section wave-top">
      <div className="founder-container">
        <div className="founder-image">
          <img src={PranjalYadav} alt="Founder of Ardra AI" />
          <div className="founder-name">
            <h2>Pranjal Narbir Yadav</h2>
            <p>2x Founder, CEO of Ardra AI</p>
          </div>
        </div>

        <div className="founder-text">
          <h2>Meet Our Founder</h2>
          <p>
            "Our founder is a nationally awarded visionary leader in the
            technology space, committed to driving innovation and excellence in
            AI and sustainability. With years of experience in the field, they
            have transformed Ardra AI from an idea into a cutting-edge company
            revolutionizing an industry vertical. Passionate about
            sustainability and technological advancement, our founder leads with
            a commitment to creating impactful solutions that enhance
            operational efficiency at scale and drive progress towards a greener
            future."
          </p>
          <a
            href="https://www.linkedin.com/in/pranjal-narbir-yadav-268589229/"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: "flex",
              justifyContent: "center",
              textDecoration: "none",
            }}
          >
            <i className="fa-brands fa-linkedin linkedin-icon"></i>
          </a>
        </div>
      </div>
      <header className="team-header">
        <div className="team-header-content">
          <h1 className="gradient-text">Founding Team</h1>
          <p>
            "We are a team of Engineers, Data Scientists, Urban Architects, and
            Energy Auditors dedicated to transforming the energy sector with
            innovative AI solutions."
          </p>
        </div>
      </header>
      <section className="team-member-cards">
        {teamMembers.map((member, index) => (
          <div className={`team-member-card member-${index + 1}`} key={index}>
            <div className="card-content">
              <div className="front">
                <img src={member.img} alt={member.name} />
                <h2 className="gradient-text">{member.name}</h2>
                <p>{member.role}</p>
              </div>
              <div className="back">
                <p className="member-description">"{member.description}"</p>
                <a
                  href={member.linkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa-brands fa-linkedin linkedin-icon"></i>
                </a>
              </div>
            </div>
          </div>
        ))}
      </section>
    </div>
  );
}

export default Team;
