import React, { useState } from "react";
import { Link } from "react-router-dom";
import BlogData from "./BlogData";
import "./BlogPage.css";
import Pagination from "./Pagination/Pagination";
import TransformingEnergyEfficiencymain from "../../Assist/Blogs/Transforming-Energy-Efficiency-main.jpg";
import { Helmet } from "react-helmet";

const BlogCaseStudies = () => {
  const caseStudies = BlogData.filter(item => item.category === "Case-study");

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPageFirstPage = 7;
  const itemsPerPageOtherPages = 9;

  const totalPages = Math.ceil(
    (caseStudies.length + 1 - itemsPerPageFirstPage) / itemsPerPageOtherPages +
      1
  );

  const startIdx =
    currentPage === 1
      ? 1
      : itemsPerPageFirstPage + (currentPage - 2) * itemsPerPageOtherPages;
  const endIdx =
    currentPage === 1
      ? itemsPerPageFirstPage
      : startIdx + itemsPerPageOtherPages;

  const handlePageChange = page => {
    setCurrentPage(page);
    window.scrollTo(0, 0);
  };

  return (
    <div className="blog-page">
      <Helmet>
        <title> Ardra AI | Energy Management | Case Studies</title>
        <meta
          name="description"
          content="Explore detailed case studies on how Ardra.ai's Energy Management System (EMS) transforms energy efficiency and helps businesses achieve sustainability goals."
        />
        <meta
          name="keywords"
          content="Case Studies, Energy Management System, EMS, Energy efficiency solutions, AI in energy management case studies, Real-world EMS case studies, Transforming energy efficiency, Ardra.ai case studies, Energy data analytics case studies, Smart energy controls, Predictive maintenance case study, Energy consumption insights, Sustainable energy solutions, Cost reduction with EMS, Operational efficiency case study, IoT in energy management case studies, Real-time energy monitoring case studies, AI-driven EMS solutions case study, Greenhouse gas reduction strategies, Sustainable business practices in energy management."
        />
        <meta name="robots" content="index, follow" />
        <meta
          property="og:title"
          content="Case Studies | Energy Management | Ardra.ai"
        />
        <meta
          property="og:description"
          content="Learn how Ardra.ai's Energy Management System (EMS) optimizes energy usage through real-time monitoring, AI, and IoT-driven solutions."
        />
        <meta property="og:image" content={TransformingEnergyEfficiencymain} />
        <meta
          property="og:url"
          content="https://www.ardra.ai/blog/case-studies"
        />
        <meta property="og:type" content="website" />
      </Helmet>

      <div className="all-blogs">
        <h1 className="blog-heading">Case Studies</h1>
        {/* Navigation Bar */}
        <nav className="blog-nav">
          <ul className="blog-nav-list">
            <li className="blog-nav-item">
              <Link to="/blog/articles" className="blog-nav-link">
                Articles
              </Link>
            </li>
            <li className="blog-nav-item">
              <Link to="/blog/case-studies" className="blog-nav-link active">
                Case Studies
              </Link>
            </li>
            <li className="blog-nav-item">
              <Link to="/blog/product-spotlight" className="blog-nav-link">
                Product Spotlight
              </Link>
            </li>
          </ul>
        </nav>

        {/* Main Blog Item - Show only on the first page */}
        {currentPage === 1 && caseStudies.length > 0 && (
          <div className="blog-main-item">
            <img
              src={TransformingEnergyEfficiencymain}
              alt="Main Blog"
              className="blog-main-item-image"
            />
            <div className="blog-main-item-content">
              <p className="blog-main-item-category">Case-study</p>
              <a href={caseStudies[0].link} className="blog-main-item-link">
                <h3 className="blog-main-item-title">{caseStudies[0].title}</h3>
              </a>
              <p className="blog-main-item-summary">{caseStudies[0].summary}</p>
            </div>
          </div>
        )}

        {currentPage === 1 && <hr className="blog-divider" />}

        {/* Blog Items */}
        <div className="blog-items">
          {caseStudies.slice(startIdx, endIdx).map(item => (
            <div key={item.id} className="blog-item">
              {item.image && (
                <img
                  src={item.image}
                  alt={item.title}
                  className="blog-item-image"
                />
              )}
              <p className="blog-item-category">{item.category}</p>
              <a href={item.link} className="blog-item-link">
                <h3 className="blog-item-title">{item.title}</h3>
              </a>
              <p className="blog-item-summary">{item.summary}</p>
            </div>
          ))}
        </div>

        {/* Pagination Component */}
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default BlogCaseStudies;
